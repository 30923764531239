import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import {
  Display,
} from "../components/typography"
import PageTitle from "../components/pageTitle"
import NewsWrapper from "../components/newsWrapper"

const NewsListPage = ({ data: { news } }) => {
  // console.log(news)
  return (
    <Layout>
      <SEO title={"Notizie"} />
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>
                <Display>Notizie</Display>
              </PageTitle>
            </Col>
          </Row>
          <Row gutterWidth={64}>
            {news.nodes.map(news => (
              <Col md={6} key={news.originalId}>
                <NewsWrapper news={news} />
              </Col>
            ))}
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default NewsListPage

export const query = graphql`
  query AllNewsQuery {
    news: allDatoCmsNews(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        date(locale: "it", formatString: "D MMMM YYYY")
        summary
        originalId
        image {
          fluid(imgixParams: { fit: "crop", w: "600", h: "600" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        mobileImage {
          fluid(imgixParams: { fit: "crop", w: "600", h: "600" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
  }
`
